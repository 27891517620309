/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import * as PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {useStaticQuery, graphql} from "gatsby";

function SEO({description, lang, meta, title, doxygen}) {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
    );

    const pageTitle = `${title} | ${site.siteMetadata.title}`;
    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={pageTitle}
            meta={[
                {name: `description`, content: metaDescription},
                {property: 'og:title', content: pageTitle},
                {property: `og:description`, content: metaDescription},
                {property: 'og:image', content: 'https://sqlapi.com/icons/icon-384x384.png'}
            ].concat(meta)}
        >
            <link rel="stylesheet" href="/css/bootstrap.min.css" type="text/css"/>
            {doxygen &&
            <link href="/css/doxygen/doxygen.css" rel="stylesheet" type="text/css"/>
            }
            {doxygen &&
            <link href="/css/doxygen/tabs.css" rel="stylesheet" type="text/css"/>
            }
            <link rel="stylesheet" href="/css/sqlapi.css" type="text/css"/>
            {doxygen &&
            <script type="application/javascript" src="/js/jquery-3.4.1.slim.min.js"/>}
            {doxygen &&
            <script type="application/javascript" src="/css/doxygen/dynsections.js"/>}
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    doxygen: PropTypes.bool
};

export default SEO;
