import {graphql, Link, useStaticQuery} from "gatsby";
import * as PropTypes from "prop-types";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const NavLink = ({to, children}) => (
    <Link className="nav-item nav-link"
          to={to}
          activeClassName="active"
          partiallyActive={to !== "/"}
    >
        {children}
    </Link>
);

const Header = ({className, pageTitle}) => {
    const {site} = useStaticQuery(graphql`
    query HeaderSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    return (
        <Navbar variant="dark" expand="md" className={className || ''}>
            <Link to="/"><Navbar.Brand className="text-info">{site.siteMetadata.title}</Navbar.Brand></Link>
            <Navbar.Text className="mr-auto d-md-none">{pageTitle}</Navbar.Text>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="navbar-nav ml-md-auto">
                    <NavLink to="/">Home</NavLink>
                    <NavLink to="/HowTo/">Getting Started</NavLink>
                    <NavLink to="/ApiDoc/">Documentation</NavLink>
                    <NavLink to="/Support/">Support</NavLink>
                    <NavLink to="/Download/">Download</NavLink>
                    <NavLink to="/Order/">Order</NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

Header.propTypes = {
    className: PropTypes.string,
    pageTitle: PropTypes.node.isRequired
};

export default Header;
