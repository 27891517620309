const tutorial = [
    {to: "/HowTo/", name: "Hello, World!"},
    {id: "connect", to: "/HowTo/connect/", name: "Connect to Database"},
    {to: "/HowTo/transactions/", name: "Transactions"},
    {to: "/HowTo/commands/", name: "Execute SQL Command"},
    {to: "/HowTo/procedures/", name: "Call Stored Procedure"},
    {to: "/HowTo/bind/", name: "Bind Input Parameters"},
    {to: "/HowTo/fetch/", name: "Fetch Result Set(s)"},
    {to: "/HowTo/performance/", name: "Bulk Fetching"},
    {id: "output-params", to: "/HowTo/return_param/", name: "Get Output Parameters"},
    {id: "blob", to: "/HowTo/blobs/", name: "Handle Long/CLob/BLob"},
    {to: "/HowTo/datetime/", name: "Work with Date/Time"},
    {id: "errors", to: "/HowTo/errors/", name: "Error Handling"},
    {to: "/HowTo/native_api/", name: "Use Native API"},
    {to: "/HowTo/query_example/", name: "Code Examples"},
];

export default tutorial;
