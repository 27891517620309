/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import * as PropTypes from "prop-types";
import _ from "lodash";

import SEO from "./seo";
import Header from "./header";
import Footer from "./footer";

const ContainerRow = ({children}) => (
    <div className="container-fluid site-max-width">
        <div className="row px-md-4">
            {children}
        </div>
    </div>
);

const Layout = ({
                    pageTitle,
                    htmlTitle,
                    meta,
                    seoProps,
                    pageNav,
                    sidebarNav,
                    mobileNav,
                    hero,
                    children,
                    pageFooter
                }) => {
    if (!htmlTitle) {
        if (_.isString(pageTitle)) {
            htmlTitle = pageTitle;
        } else {
            throw new Error(`htmlTitle is missing`)
        }
    }

    const bodyCol = sidebarNav ? 'col-md-9' : 'col-12';
    const sidebarCol = `col-md-3 py-3 ${mobileNav !== undefined ?
        'd-none d-md-flex' : 'd-flex'} flex-md-column d-print-none`;

    return (
        <>
            <SEO title={htmlTitle} meta={meta} {...seoProps}/>

            <div className="bg-black fixed-top">
                <ContainerRow>
                    <Header className="col-12"
                            pageTitle={pageTitle}
                    />
                </ContainerRow>
            </div>

            {hero}


            <ContainerRow>
                {/* Page navigation */}
                {pageNav &&
                <nav className="col-12 px-0">{pageNav}</nav>
                }

                {/* Mobile navigation (replaces sidebar) */}
                {mobileNav &&
                <nav className="col-12 d-md-none px-0">{mobileNav}</nav>
                }
                {/* Sidebar */}
                {sidebarNav &&
                <div className={sidebarCol}>{sidebarNav}</div>
                }

                {/* Body */}
                <main className={`${bodyCol} pt-3 pb-0`}>{children}</main>
            </ContainerRow>

            {/* Page specific footer */}
            {pageFooter &&
            <div className="bg-dark text-light">{pageFooter}</div>
            }

            {/* Footer */}
            <div className="bg-black text-light py-5 d-print-none">
                <ContainerRow>
                    <Footer className="col-12"/>
                </ContainerRow>
            </div>
        </>
    )
};

Layout.propTypes = {
    pageTitle: PropTypes.node.isRequired,
    meta: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired
    })),
    pageNav: PropTypes.node,
    sidebarNav: PropTypes.node,
    mobileNav: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.node]),
    children: PropTypes.node.isRequired,
    pageFooter: PropTypes.node
};

export default Layout;
